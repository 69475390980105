import React, { ReactNode } from "react";
import BaseComponent from "../../base/base-component";
import {
  ISvgIconRectProproperties,
  ISvgIconCircleProproperties,
  ISvgIconPathProperties,
  ISvgIconProperties,
} from "./svg-icon-interface";

export default class SvgIcon extends BaseComponent<ISvgIconProperties> {
  public render(): ReactNode {
    const {
      width,
      height,
      viewBox,
      fill = "none",
      xmlns = "http://www.w3.org/2000/svg",
      paths,
      circles,
      rect,
    } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        xmlns={xmlns}
      >
        {rect
          ? rect.map((rect: ISvgIconRectProproperties, index: number) => {
              return (
                <rect
                  key={index}
                  rx={rect.rx}
                  width={rect.width}
                  height={rect.height}
                  fill={rect.fill}
                />
              );
            })
          : ""}
        {circles
          ? circles.map((circle: ISvgIconCircleProproperties, index: number) => {
              return (
                <circle
                  key={index}
                  cx={circle.cx}
                  cy={circle.cy}
                  r={circle.r}
                  fill={circle.fill}
                />
              );
            })
          : ""}
        {paths 
          ? paths.map((path: ISvgIconPathProperties, index: number) => {
              return (
                <path
                  key={index}
                  clipRule={path.clipRule}
                  fillRule={path.fillRule}
                  d={path.command}
                  fill={path.fill}
                  fillOpacity={path.fillOpacity}
                  transform={path.transform}
                />
              );
            })
          : ""}
      </svg>
    );
  }
}
