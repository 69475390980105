export enum Route {
  HOME = '/',
  OVERALL = '/overall',
  PARTICIPATION = '/participation',
  COMPETITION = '/competition',
  REPRESENTATION = '/representation',
  POLARIZATION = '/polarization',
  NOT_FOUND = '/404',
  ABOUT = '/about-us',
  READ_THE_REPORT = '/read-the-report',
  MEDIA = '/media',
  METHODOLOGY = '/methodology',
  TAKE_ACTION = '/take-action',
  PRIVACY_POLICY = '/privacy-policy',
  CONTACT = '/contact',
  STATE_OF_REFORM = '/state-of-reform',
  DATA = 'https://stateofdemocracy.com',
}

export enum SORRoute {
  HOME = '/state-of-reform',
  STATE_OF_DEMOCRACY = 'https://stateofdemocracy.com',
  RANKED_CHOICE_VOTING = '/state-of-reform/ranked-choice-voting',
  OPEN_PRIMARIES = '/state-of-reform/open-primaries',
  INDEPENDENT_REDISTRICTING = '/state-of-reform/independent-redistricting',
  ABOUT = '/state-of-reform/about',
  CONTACT = '/state-of-reform/contact',
  PRIVACY_POLICY = '/state-of-reform/privacy-policy',
}
